import React, { useEffect, useState } from 'react';

import { FormattedMessage } from '../../util/reactIntl';
import { isFieldForListingType } from '../../util/fieldHelpers';
import { formatMoney } from '../../util/currency';


import { Heading, NamedLink } from '../../components';

import css from './ListingPage.module.css';

import { types as sdkTypes } from '../../util/sdkLoader';
const { Money } = sdkTypes;

const SectionDetailsMaybe = props => {
  const { publicData, listing, marketplaceCurrency, metadata = {}, listingFieldConfigs, isFieldForCategory, intl, onGetUserByUserId, author } = props;

  let address = publicData?.location?.address;
  if (address?.split(',').length > 2) {
    address = address?.split(',');
    address = address?.slice(1, address.length);
    address = address?.join(',');
  }
  const price = new Money(listing.attributes.price.amount, marketplaceCurrency);
  const securityDeposit = publicData?.securityDeposit ? new Money(publicData?.securityDeposit, marketplaceCurrency) : null;
  const firstInstalment = publicData?.firstInstalment ? new Money(publicData?.firstInstalment, marketplaceCurrency) : null;
  const secondInstalment = publicData?.secondInstalment ? new Money(publicData?.secondInstalment, marketplaceCurrency) : null;
  const thirdInstalment = publicData?.thirdInstalment ? new Money(publicData?.thirdInstalment, marketplaceCurrency) : null;
  const createdAt = listing?.attributes?.createdAt?.toLocaleString();

  const listingApprovedDate = listing?.attributes?.publicData?.listingApprovedDate;

  if (!publicData || !listingFieldConfigs) {
    return null;
  }

  const pickListingFields = (filteredConfigs, config) => {
    const { key, schemaType, enumOptions, showConfig = {} } = config;
    const listingType = publicData.listingType;
    const isTargetListingType = isFieldForListingType(listingType, config);
    const isTargetCategory = isFieldForCategory(config);

    const { isDetail, label } = showConfig;
    const publicDataValue = publicData[key];
    const metadataValue = metadata[key];
    const value = publicDataValue || metadataValue;

    if (isDetail && isTargetListingType && isTargetCategory && typeof value !== 'undefined') {
      const findSelectedOption = enumValue => enumOptions?.find(o => enumValue === `${o.option}`);
      const getBooleanMessage = value =>
        value
          ? intl.formatMessage({ id: 'SearchPage.detailYes' })
          : intl.formatMessage({ id: 'SearchPage.detailNo' });
      const optionConfig = findSelectedOption(value);

      return schemaType === 'enum'
        ? filteredConfigs.concat({ key, value: optionConfig?.label, label })
        : schemaType === 'boolean'
          ? filteredConfigs.concat({ key, value: getBooleanMessage(value), label })
          : schemaType === 'long'
            ? filteredConfigs.concat({ key, value, label })
            : filteredConfigs;
    }
    return filteredConfigs;
  };

  const existingListingFields = listingFieldConfigs.reduce(pickListingFields, []);
  const mobilityValue = listingFieldConfigs[0]?.enumOptions?.find(item => item?.label == existingListingFields[0]?.value)?.option;
  // const userId = publicData?.verifiedBy;
  const userId = author?.attributes?.profile?.publicData?.verifiedBy;
  const [verifiedUserName, setverifiedUserName] = useState('');
  useEffect(() => {
    if (userId) {
      const getUser = async () => {
        const userName = await onGetUserByUserId(userId);
        if (userName) {
          setverifiedUserName(userName);
        }
      }
      getUser();
    }
  }, [userId]);

  const verifiedUserLink = (
    !!userId ? (<NamedLink
      className={css.topbarLink}
      name="ProfilePage"
      params={{ id: userId }}

    >
      <span className={css.topbarLinkLabel}>
        {verifiedUserName}
      </span>
    </NamedLink>) : null
  );
  // Function to determine the platform from the video URL
  const getVideoPlatform = (url) => {
    if (url.includes('youtube.com') || url.includes('youtu.be')) {
      return 'youtube';
    } else if (url.includes('dailymotion.com') || url.includes('dai.ly')) {
      return 'dailymotion';
    }
    else {
      // Handle other platforms or unsupported URLs
      return null;
    }
  };

  // Function to generate the appropriate iframe based on platform
  const renderVideoIframe = (url) => {
    if (url) {
      const platform = getVideoPlatform(url);

      if (platform === 'youtube') {
        // Extract video ID from YouTube URL
        const youtubeVideoId = url.match(/(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/)[1];
        const youtubeEmbedUrl = `https://www.youtube.com/embed/${youtubeVideoId}?autoplay=1&amp;mute=1`;

        return (
          <iframe
            width="560"
            height="315"
            src={youtubeEmbedUrl}
            title="YouTube Video Player"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        );
      } else if (platform === 'dailymotion') {
        // Extract video ID from Dailymotion URL
        const dailymotionVideoId = url.split('/').pop().split('_')[0];
        const dailymotionEmbedUrl = `https://www.dailymotion.com/embed/video/${dailymotionVideoId}?autoplay=1&amp;mute=1`;

        return (
          <iframe
            width="560"
            height="315"
            src={dailymotionEmbedUrl}
            title="Dailymotion Video Player"
            frameBorder="0"
            allowFullScreen
          />
        );
      }
      else {
        // Handle unsupported platforms or invalid URLs
        return <p>Unsupported video platform or invalid URL
          <a href={url} target="_blank">click here to view video in original platform</a>
        </p>;
      }
    }
  };

  const GuardianAngelLabelLink = (
    <span className={css.privacyLink}>
      <a className={css.anchor} href='https://world-like-home.community/t/contrat-cadre-service-esa/3643'  target="_blank">  {intl.formatMessage({ id: 'CustomExtendedDataField.packs' })} </a>
    </span>
  );
  const la_formationLabel_Link = (
    <span className={css.privacyLink}>
      <a className={css.anchor} href='https://world-like-home.community/t/formation-des-esians-devenir-ange-gardien-pour-les-etudiants-en-mobilite-vers-la-france/5854'  target="_blank">  {intl.formatMessage({ id: 'CustomExtendedDataField.la_formation' })} </a>
    </span>
  );

  return (
    <section className={css.sectionDetails}>
      <Heading as="h2" rootClassName={css.sectionHeading}>
        <FormattedMessage id="ListingPage.detailsTitle" />
      </Heading>
      <ul className={css.details}>
        {existingListingFields?.map(detail => {

          let displayLabel = intl.formatMessage({ id: `CustomExtendedDataField.${detail.key}Label` });

          switch (detail.key) {
            case 'GuardianAngel':
              displayLabel = <span> {intl.formatMessage({ id: 'CustomExtendedDataField.GuardianAngelLabel' }, { link: GuardianAngelLabelLink })} {props?.required && <sup>*</sup>}</span>
              break;
            case 'Certification_Guardian_Angel':
              displayLabel = <span> {intl.formatMessage({ id: 'CustomExtendedDataField.Certification_Guardian_AngelLabel' }, { link: la_formationLabel_Link })} {props?.required && <sup>*</sup>}</span>
              break;

          }


          return (
            <li key={detail.key} className={css.detailsRow}>
              <span className={css.detailLabel}>{displayLabel}</span>
              <span>{intl.formatMessage({ id: `CustomExtendedDataField.${detail.value}Label` })}</span>
            </li>
          )
        })}


        {publicData.listingType == "Host_Family" ?
          <>
            {publicData?.Capacity ?
              <li className={css.detailsRow}>
                <span className={css.detailLabel}>{intl.formatMessage({ id: 'EditListingDetailsForm.homeCapacity' })}</span>
                <span>{publicData.Capacity}</span>
              </li>
              : null}
            {price ?
              <li className={css.detailsRow}>
                <span className={css.detailLabel}>{intl.formatMessage({ id: 'EditListingDetailsForm.weeklyCompensation' })}</span>
                <span>{formatMoney(intl, price)}</span>
              </li>
              : null}
            {publicData?.Initial_Duration ?
              <li className={css.detailsRow}>
                <span className={css.detailLabel}>{intl.formatMessage({ id: 'EditListingDetailsForm.Initial_Duration' })}</span>
                <span>{publicData.Initial_Duration}</span>
              </li>
              : null}
            {address ?
              <li className={css.detailsRow}>
                <span className={css.detailLabel}>{intl.formatMessage({ id: 'EditListingLocationForm.address' })}</span>
                <span>{address}</span>
              </li>
              : null}
            {publicData?.Possibility_of_prolongation ?
              <li className={css.detailsRow}>
                <span className={css.detailLabel}>{intl.formatMessage({ id: 'EditListingDetailsForm.Possibility_of_prolongationLabel' })}</span>
                <span>{publicData.Possibility_of_prolongation ?
                  intl.formatMessage({ id: 'EditListingDetailsForm.yes' })
                  : intl.formatMessage({ id: 'EditListingDetailsForm.no' })
                }
                </span>
              </li>
              : null}
          </>
          : null}

        {publicData.listingType == "Help_to_get_an_Accommodation" ?
          <>
            {price ?
              <li className={css.detailsRow}>
                <span className={css.detailLabel}>{intl.formatMessage({ id: 'EditListingDetailsForm.Compensation' })}</span>
                <span>{formatMoney(intl, price)}</span>
              </li>
              : null}
            {address ?
              <li className={css.detailsRow}>
                <span className={css.detailLabel}>{intl.formatMessage({ id: 'EditListingLocationForm.address' })}</span>
                <span>{address}</span>
              </li>
              : null}
            <li className={css.detailsRow}>
              <span className={css.detailLabel}>{intl.formatMessage({ id: 'EditListingDetailsForm.createdAt' })}</span>
              <span>{createdAt}</span>
            </li>
            <li className={css.detailsRow}>
              <span className={css.detailLabel}>{intl.formatMessage({ id: 'EditListingDetailsForm.validatedBy' })}</span>
              <span>{verifiedUserLink}</span>
            </li>
          </>
          : null}
        <div>
          {renderVideoIframe(publicData?.listingVideoLink)}
        </div>
        {publicData.listingType == "Accommodation" ?
          <>
            {publicData?.Capacity ?
              <li className={css.detailsRow}>
                <span className={css.detailLabel}>{intl.formatMessage({ id: 'EditListingDetailsForm.capacityLabel' })}</span>
                <span>{publicData.Capacity}</span>
              </li>
              : null}
            {price ?
              <li className={css.detailsRow}>
                <span className={css.detailLabel}>{intl.formatMessage({ id: 'EditListingPricingAndStockForm.rentLabel' })}</span>
                <span>{formatMoney(intl, price)}</span>
              </li>
              : null}
            {securityDeposit ?
              <li className={css.detailsRow}>
                <span className={css.detailLabel}>{intl.formatMessage({ id: 'EditListingPricingAndStockForm.securityDeposit' })}</span>
                <span>{formatMoney(intl, securityDeposit)}</span>
              </li>
              : null}
            {address ?
              <li className={css.detailsRow}>
                <span className={css.detailLabel}>{intl.formatMessage({ id: 'EditListingLocationForm.city' })}</span>
                <span>{address}</span>
              </li>
              : null}
            <li className={css.detailsRow}>
              <span className={css.detailLabel}>{intl.formatMessage({ id: 'EditListingDetailsForm.createdAt' })}</span>
              <span>{createdAt}</span>
            </li>
            {listingApprovedDate ?
              <li className={css.detailsRow}>
                <span className={css.detailLabel}>{intl.formatMessage({ id: 'EditListingDetailsForm.validatedBy' })}</span>
                <span>{verifiedUserLink}</span>
              </li>
              : null}
          </>
          : null}

        {publicData.listingType == "Welcome" ?
          <>
            {/* <li className={css.detailsRow}>
            <span className={css.detailLabel}>{intl.formatMessage({ id: 'CustomExtendedDataField.Select_the_type_of_mobilitySection' })}</span>
            <span>{intl.formatMessage({ id: 'CustomExtendedDataField.Internal_mobilityLabel' })}</span>
          </li> */}
            {price ?
              <li className={css.detailsRow}>
                <span className={css.detailLabel}>{intl.formatMessage({ id: 'EditListingPricingAndStockForm.compensationLabel' })}</span>
                <span>{formatMoney(intl, price)}</span>
              </li>
              : null}
            {address ?
              <li className={css.detailsRow}>
                <span className={css.detailLabel}>{intl.formatMessage({ id: 'EditListingLocationForm.city' })}</span>
                <span>{address}</span>
              </li>
              : null}
            <li className={css.detailsRow}>
              <span className={css.detailLabel}>{intl.formatMessage({ id: 'EditListingDetailsForm.createdAt' })}</span>
              <span>{createdAt}</span>
            </li>

            {listingApprovedDate ?
              <li className={css.detailsRow}>
                <span className={css.detailLabel}>{intl.formatMessage({ id: 'EditListingDetailsForm.validatedBy' })}</span>
                <span>{verifiedUserLink}</span>
              </li>
              : null}
          </>
          : null}

        {publicData.listingType == "ESA" ?
          <>
            {price ?
              <li className={css.detailsRow}>
                <span className={css.detailLabel}>{intl.formatMessage({ id: 'EditListingPricingAndStockForm.compensationLabel' })}</span>
                <span>{formatMoney(intl, price)}</span>
              </li>
              : null}
            {firstInstalment ?
              <li className={css.detailsRow}>
                <span className={css.detailLabel}>{intl.formatMessage({ id: 'EditListingPricingAndStockForm.firstInstalment' })}</span>
                <span>{formatMoney(intl, firstInstalment)}</span>
              </li>
              : null}
            {secondInstalment ?
              <li className={css.detailsRow}>
                <span className={css.detailLabel}>{intl.formatMessage({ id: 'EditListingPricingAndStockForm.secondInstalment' })}</span>
                <span>{formatMoney(intl, secondInstalment)}</span>
              </li>
              : null}
            {thirdInstalment ?
              <li className={css.detailsRow}>
                <span className={css.detailLabel}>{intl.formatMessage({ id: 'EditListingPricingAndStockForm.thirdInstalment' })}</span>
                <span>{formatMoney(intl, thirdInstalment)}</span>
              </li>
              : null}
            {address ?
              <li className={css.detailsRow}>
                <span className={css.detailLabel}>{intl.formatMessage({ id: 'EditListingLocationForm.city' })}</span>
                <span>{address}</span>
              </li>
              : null}
            <li className={css.detailsRow}>
              <span className={css.detailLabel}>{intl.formatMessage({ id: 'EditListingDetailsForm.createdAt' })}</span>
              <span>{createdAt}</span>
            </li>
            {listingApprovedDate ?
              <li className={css.detailsRow}>
                <span className={css.detailLabel}>{intl.formatMessage({ id: 'EditListingDetailsForm.validatedBy' })}</span>
                <span>{verifiedUserLink}</span>
              </li>
              : null}
          </>
          : null}

        {publicData.listingType == "Flatsharing" ?
          <>
            {price ?
              <li className={css.detailsRow}>
                <span className={css.detailLabel}>{intl.formatMessage({ id: 'EditListingPricingAndStockForm.rentLabel' })}</span>
                <span>{formatMoney(intl, price)}</span>
              </li>
              : null}
            {securityDeposit ?
              <li className={css.detailsRow}>
                <span className={css.detailLabel}>{intl.formatMessage({ id: 'EditListingPricingAndStockForm.securityDeposit' })}</span>
                <span>{formatMoney(intl, securityDeposit)}</span>
              </li>
              : null}
            {address ?
              <li className={css.detailsRow}>
                <span className={css.detailLabel}>{intl.formatMessage({ id: 'EditListingLocationForm.city' })}</span>
                <span>{address}</span>
              </li>
              : null}
            <li className={css.detailsRow}>
              <span className={css.detailLabel}>{intl.formatMessage({ id: 'EditListingDetailsForm.createdAt' })}</span>
              <span>{createdAt}</span>
            </li>
            {listingApprovedDate ?
              <li className={css.detailsRow}>
                <span className={css.detailLabel}>{intl.formatMessage({ id: 'EditListingDetailsForm.validatedBy' })}</span>
                <span>{verifiedUserLink}</span>
              </li>
              : null}
          </>
          : null}
      </ul>
    </section>
  );
};

export default SectionDetailsMaybe;
